// services
import { webCaptureUrl } from "@/services/config/configService";
import { errorMessage } from "@/services/error/errorService";

// utils
import { isEmpty } from "@/utils";

// mixins
import { basicScanMixin } from "@/mixins/shared/scan/basicScanMixin";

// vuex
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("user");
/**
 * wingScanMixin implements Kofax web Capture Service
 */
/*eslint no-undef: "OFF"*/
export const wingScanMixin = {
  mixins: [basicScanMixin],
  computed: {
    ...mapGetters({ enableWebScan: "enableWebScan" }),
    /**
     * get Kofax-Atalasoft web Capture Service Name
     * overwrite computed basicScanMixin.webCaptureServiceName()
     * @return {string} return Kofax web Capture Service Name
     */
    webCaptureServiceName() {
      return "Kofax WebCapture Service";
    },

    /**
     * get default Twain Scanner using Atalasoft WebScanning
     * overwrite computed basicScanMixin.defaultScanner()
     * @return {any}
     */
    defaultScanner() {
      return Atalasoft.Controls.Capture.WebScanning?._scanClient
        ?.defaultScanner;
    }
  },
  methods: {
    /**
     * Asynchronously Initialize WebScanning control
     * install or start the WebCapture plugin/service,
     * verify licensing,
     * initialize scanning on the client,
     * and then collect a list of available scanners
     */
    async initializeWebScanning() {
      try {
        this.clearCurrentError();
        this.clearUploadError();
        this.isLoadingScanners = true;

        // The URL of the web request handler.
        console.log(`${this.$options.name} wabCaptureUrl:`, webCaptureUrl);

        // If it is successful,
        // it will populate a scanner-list control and enable a scan button and/or import button,
        // if they exist with the appropriate classes:

        // Web Capture Javascript control should be initialized.
        // This method must be called to initialize the WebScanning component.
        // The init object must contain a handlerUrl property,
        // all other properties - listed below - are optional.
        // Initialization is asynchronous!
        //
        // When initialization is complete (licensing has been verified,
        // a scanner list has been constructed and it is possible to
        // initiate scan or import operations) WebScanning calls the
        // onScanClientReady call-back from the init object
        await Atalasoft.Controls.Capture.WebScanning.initialize({
          handlerUrl: webCaptureUrl,
          onScanError: this.scanErrorHandler,
          onScanClientReady: this.scanClientReadyHandler,
          onImageAcquired: this.imageAcquiredHandler,
          onScanCompleted: this.scanCompletedHandler,
          onUploadStarted: this.uploadStartedHandler,
          onUploadCompleted: this.uploadCompletedHandler,
          onUploadError: this.uploadErrorHandler,
          scanningOptions: this.defaultScanningOptions(),
          uploadOptions: this.uploadOptions()
        });
      } catch (e) {
        console.error(e);
        this.setCurrentError(e.toString());
      }
    },

    /**
     * upload Options
     * @return {{formData: {recordId: number, recordName: string, token: string}}}
     */
    uploadOptions() {
      return {
        formData: {
          recordId: this.currentRecord?.id ?? -1,
          recordName: this.currentRecord?.name ?? "Record",
          token: this.currentUser?.token
        }
      };
    },

    /**
     * Checks if Web Scanning control is initialized or not.
     * overwrite computed basicScanMixin.isInitialized()
     * @return {boolean}
     */
    isInitialized() {
      return Atalasoft.Controls.Capture.WebScanning?.isInitialized() ?? false;
    },

    /**
     * This handler is called when scanning initialization is complete,
     * no matter whether it was successful or not.
     * Note that the scanner list could still be empty if no scanners were detected.
     *
     * Important: This handler may never be called! Some possible causes:
     *  - The user declines to install/accept the WebCapture plugin
     *  - Plugin installation fails.
     *  - The browser or OS is unsupported.
     */
    scanClientReadyHandler() {
      try {
        if (this.isInitialized()) {
          console.log(`Scanning initialization is completed.`);

          this.displayScanners();

          const options =
            Atalasoft.Controls.Capture.WebScanning.scanningOptions;
          console.log(`scanningOptions: `, options);

          if (options?.deliverables?.localFile?.format) {
            if (Atalasoft.Controls.Capture.WebScanning.LocalFile) {
              console.log(`scanClientReadyHandler() setEncryptionKey`);
              Atalasoft.Controls.Capture.WebScanning.LocalFile.setEncryptionKey(
                "LocalFile",
                this.setEncryptionKeyCallback
              );
            }
          }
        } else {
          console.warn("Scanning initialization not completed.");
        }
      } catch (e) {
        this.setCurrentError(e.toString());
      } finally {
        this.isLoadingScanners = false;
      }
    },

    /**
     * Image Acquired Handler
     * @param {type:String, timeStamp:number} event Image index
     * @param {{discard:boolean, localFile:*, id:number, path  }} image Acquired image object
     */
    imageAcquiredHandler(event, image) {
      try {
        console.log(`ImageAcquiredHandler() event:`, event);
        console.log(`ImageAcquiredHandler() image:`, image);

        this.acquiredImages.push(image.id);

        const options = Atalasoft.Controls.Capture.WebScanning.scanningOptions;

        if (options?.deliverables?.localFile?.format === "tif") {
          //
          // Save each scanned image to encrypted local file
          // If Preferred way (configure scanning options deliverables settings to save each scanned image to encrypted local file) to handle scanned images is used
          //  save each scanned image to encrypted local file in a desired format and discard image itself
          //

          console.log(
            `imageAcquiredHandler()`,
            "Save each scanned image to encrypted local file"
          );

          // code from Atalasoft:
          //
          // // Remove image from memory
          // image.discard = true;
          //
          // // Use LocalFile API for upload scan result to server with specified settings
          // Atalasoft.Controls.Capture.WebScanning.LocalFile.asBase64String(
          //   image.localFile,
          //   "jpg",
          //   {
          //     quality: 5
          //   },
          //   function(data) {
          //     Atalasoft.Controls.Capture.UploadToCaptureServer.uploadToServer(
          //       data
          //     );
          //   }
          // );
        }
      } catch (e) {
        this.setCurrentError(e);
      }
    },

    /**
     * scan Completed Handler
     * remarks: This handler is called when scanning initialization is complete.
     * @param eventName
     * @param {{success: boolean, error:{message: string}}} eventObj
     */
    scanCompletedHandler(eventName, eventObj) {
      try {
        console.log(`${this.$options.name} eventName:`, eventName);
        console.log(`${this.$options.name} eventObj:`, eventObj);

        if (eventObj?.success ?? false) {
          //
          // Handle scan Completed successfully
          //
          console.log(`Scanning completed successfully`);
        } else {
          //
          // Handle scan Completed with error(s)
          //
          if (eventObj?.error) {
            console.error(
              `scanCompletedHandler() params:`,
              eventObj?.error?.message
            );
          }
        }
      } catch (e) {
        this.setCurrentError(e);
      } finally {
        this.isScanning = false;
      }
    },

    /**
     * upload Started Handler
     */
    uploadStartedHandler() {
      try {
        this.isUploadingPages = true;
        this.clearUploadError();
        console.log(`Upload scanned images started ...`);
      } catch (e) {
        this.setUploadError(e);
      }
    },

    /**
     * upload Completed Handler
     * @param event
     * @param {{success: boolean}} eventObj
     */
    uploadCompletedHandler(event, eventObj) {
      try {
        console.log(`uploadCompletedHandler() event:`, event);
        console.log(`uploadCompletedHandler() eventObj:`, eventObj);

        const success = eventObj?.success ?? false;
        console.log(`${this.$options.name} success:`, success ?? false);

        this.afterUploadCompleted(success);
      } catch (e) {
        this.setUploadError(e);
      } finally {
        this.isUploadingPages = false;
      }
    },

    /**
     * upload ErrorH andler
     * @param {string} error
     * @param {{handlerUrl:string, responseStatusMsg:number, responseObject:any,  timeout:number}} params
     */
    uploadErrorHandler(error, params) {
      try {
        console.warn(`uploadErrorHandler() error:`, error);
        console.warn(`uploadErrorHandler() handlerUrl:`, params?.handlerUrl);
        console.warn(
          `uploadErrorHandler() responseObject:`,
          params?.responseObject
        );
        console.warn(`uploadErrorHandler() timeout:`, params?.timeout);

        const error = errorMessage(
          `Error while uploading scanned images`,
          error,
          `handlerUrl: ${params?.handlerUrl}`
        );

        this.setUploadError(error);
      } catch (e) {
        this.setUploadError(e);
      } finally {
        this.isUploadingPages = false;
      }
    },

    /**
     * This handler is called to report any errors detected by WingScan/WebCapture.
     * Note that it can be called during a call to any WingScan method,
     * or asynchronously by background operations such as importFiles
     * @param {string} msg Error identifier, one of Atalasoft.Controls.Capture.Errors
     * @param {any} params additional error metadata. Object structure depends on particular error type.
     */
    scanErrorHandler(msg, params) {
      try {
        this.clearCurrentError();

        console.log(`scanErrorHandler() msg:`, msg);
        console.log(`scanErrorHandler() params:`, params);

        const paramMessage = params?.message ?? "";

        switch (msg) {
          // eslint-disable-next-line no-case-declarations
          case Atalasoft.Controls.Capture.Errors.noTwain: {
            const problem =
              "This web scanning requires that you have at least one valid TWAIN device installed and configured.";
            const hint =
              "Please ensure you install the TWAIN driver for your scanner device(s) then re-visit this site.";
            this.setCurrentError(
              errorMessage(problem, msg, paramMessage, hint)
            );
            break;
          }
          case Atalasoft.Controls.Capture.Errors.oldWindowsService: {
            const problem = `The version of ${this.webCaptureServiceName}, running on the multiuser system you're using, is out of date.`;
            const hint = "Please contact your system administrator.";
            this.setCurrentError(
              errorMessage(problem, msg, paramMessage, hint)
            );
            break;
          }
          case Atalasoft.Controls.Capture.Errors.webServiceMissed: {
            const problem = `The version of ${this.webCaptureServiceName}, running on the multiuser system you're using, is out of date.`;
            const hint = "Please contact your system administrator.";
            this.setCurrentError(
              errorMessage(problem, msg, paramMessage, hint)
            );
            break;
          }
          case Atalasoft.Controls.Capture.Errors.noPlugin: {
            const filename = params?.filename ?? "";
            const installerUrl = this.webCaptureInstallerUrl(filename);
            const problem = `${this.webCaptureServiceName} is not available`;
            const hint = `If the download does not begin automatically, please download from ${installerUrl} If you are not prompted to install, the service may be installed but not running. Enable it by running from START->All Programs->${this.webCaptureServiceName}->${this.webCaptureServiceName} Refresh your browser when completed.
            If you want to install as windows service, Use this command "msiexec /I Kofax.WebCapture.Installer.msi INSTALLASSERVICE=1"`;

            this.setCurrentError(
              errorMessage(problem, msg, paramMessage, hint)
            );
            window.open(installerUrl, "_downloadService");
            break;
          }
          case Atalasoft.Controls.Capture.Errors.oldPlugin: {
            const filename = params?.filename ?? "";
            const installerUrl = this.webCaptureInstallerUrl(filename);
            const problem = `${this.webCaptureServiceName} is out of date`;
            const hint = `If the download does not begin automatically, please download from ${installerUrl}`;

            this.setCurrentError(
              errorMessage(problem, msg, paramMessage, hint)
            );
            window.open(installerUrl, "_downloadService");
            break;
          }
          default: {
            const problem = `An error has occurred`;
            const hint = "Please contact your system administrator.";
            const paramMessage = !isEmpty(params?.message)
              ? params?.message
              : params?.statusText ?? "";
            this.setCurrentError(
              errorMessage(problem, msg, paramMessage, hint)
            );
            break;
          }
        }
      } catch (e) {
        this.setCurrentError(e);
      }
    },

    /**
     * setEncryptionKey Completion callback function.
     */
    setEncryptionKeyCallback() {
      console.log(`setEncryptionKey completed`);
    },

    /**
     * get scanning Options
     * @return {{showScannerUI: boolean, deskew: boolean, brightness: number, despeckle: boolean, showProgress: boolean, contrast: number, threshold: number, discardBlankPages: boolean, autoRotate: boolean, dpi: number, applyVRS: boolean}} return all scanning options
     */
    defaultScanningOptions() {
      return {
        // Show (true) or hide (false) the scanner's user interface during scanning.
        showScannerUI: true,
        // A value of -1 means 'let the scanner choose the threshold'
        threshold: -1,
        // 0 - do not adjust brightness
        brightness: 0,
        // 0 - do not adjust contrast
        contrast: 0,
        // If you specify applyVRS: false, VRS is not used for any post-processing.
        applyVRS: false,
        // Detect the orientation of the text in an image - right-side up, upside-down, sideways
        // - and rotates the image so the text is upright.
        // Note: If VRS is disabled, autoRotate is always disabled
        autoRotate: false,
        // Deskew is scanning jargon for 'straighten' -
        // to rotate the scanned image by a few degrees to correct for the paper being scanned slightly crooked.
        deskew: false,
        // Scanning option indicating whether 'despeckle' filter is applied to the acquired image
        despeckle: false,
        // Scanning option indicating whether 'auto crop' filter is applied to the acquired image
        autoCrop: false,
        // When this option is true, blank images are detected and discarded during scanning.
        // In duplex scanning, front and back sides of pages are discarded independently.
        discardBlankPages: false,
        // Scanning option indicating whether 'hole fill' filter is applied to the acquired image
        holeFill: false,
        dpi: 200,
        /**
         * when this option is true, the scanner is asked to display a small progress dialog during scanning.
         * These dialogs typically include a Cancel button.
         */
        showProgress: true
        //
        // Specifies configurations for pre-generated data that should be prepared
        // for each scanned image right after it's scanned
        //,
        // deliverables: {
        //   originalImageFile: {
        //     format: "tif",
        //     jpegCompression: false,
        //     quality: 75,
        //     split: true,
        //     // size of individual chunk in bytes.
        //     // Default chunk size is 10mb if split is enabled.
        //     chunkSize: 10
        //   },
        //   /**
        //    * Encrypted local file generation settings.
        //    * When specified, encrypted local file will be generated for each scanned image
        //    * and identified of that local file will be passed using localFile property
        //    * of the image object in {onImageAcquiredCallback| onImageAcquired} handler.
        //    */
        // localFile: {
        // format: "tif"
        // jpegCompression: false,
        // quality: 75,
        // split: true,
        // size of individual chunk in bytes.
        // Default chunk size is 10mb if split is enabled.
        // chunkSize: 10
        //}
        //   thumbnail: {
        //     // specifies the image format to store image data.
        //     format: "tif",
        //     // indicates whether to use JPEG compression for TIFF files.
        //     jpegCompression: false,
        //     // specifies the quality parameter if JPEG compression is enabled or JPEG format is requested.
        //     quality: 75,
        //     // thumbnail height.
        //     height: 50,
        //     // thumbnail width.
        //     width: 50
        //   }
        // }
      };
    },

    /**
     * display Scanners and select default scanner if it is available
     */
    displayScanners() {
      try {
        // list scanners
        const scanClient = Atalasoft.Controls.Capture?.WebScanning?._scanClient;

        // Set all available scanners
        this.scanners = scanClient?.scanners ?? [];

        // Set selected scanner
        if (this.scannerCount > 0) {
          const scanner = this.findScanner(this.defaultScanner);
          if (scanner) {
            this.selectedScanner = scanner;
          }
        }
      } catch (e) {
        this.setCurrentError(e);
      }
    },

    /**
     * Reloads the internal array of the available scanners and selects the last used scanner
     */
    listScanners() {
      try {
        Atalasoft.Controls.Capture.WebScanning.enumerateScanners(
          this.callbackEnumerateScanners
        );
      } catch (e) {
        this.setCurrentError(e);
      }
    },

    /**
     * Callback accepting array of available scanners.
     * If not passed, method is executed synchronously.
     * @param {{string}[]} scanners
     */
    callbackEnumerateScanners(scanners) {
      try {
        this.scanners = [];
        (scanners ?? []).forEach(scanner => {
          console.log(`scanner:`, scanner);
          this.scanners.push(scanner);
        });
      } catch (e) {
        this.setCurrentError(e);
      }
    },

    /**
     * select Scanner
     * @param {string} scanner scanner name
     */
    selectScanner(scanner) {
      try {
        Atalasoft.Controls.Capture.WebScanning.scanningOptions.scanner = scanner;
      } catch (e) {
        this.setCurrentError(e);
      }
    },

    /**
     * Get scanning Options
     * remarks: overwrite basicScanMixin.scanningOptions
     * @return {any|undefined}
     */
    scanningOptions() {
      return Atalasoft.Controls.Capture.WebScanning.scanningOptions;
    },

    /**
     * Scan pages using Selected Scanner
     */
    scan() {
      try {
        this.clearCurrentError();
        this.clearUploadError();

        this.acquiredImages = [];
        this.isScanning = true;

        const options = Atalasoft.Controls.Capture.WebScanning.scanningOptions;
        console.log(`scan() scanningOptions:`, options);
        Atalasoft.Controls.Capture.WebScanning.scan();
      } catch (e) {
        this.isScanning = false;
        this.setCurrentError(e);
      }
    },

    /**
     * Aborts the current background operation in progress, if any.
     * If there is no current background operation, it does nothing.
     * remarks: overwrite basicScanMixin.abortScan method
     */
    abortScan() {
      try {
        Atalasoft.Controls.Capture.WebScanning.abortScan();
      } catch (e) {
        console.error(e.toString());
      }
    },

    /**
     * dispose Scanning
     * remarks: overwrite basicScanMixin.disposeScanning method
     * this method should be called to shutdown all Web Capture services
     * gracefully and free all acquired resources
     * (for example, correctly close scanners that web application worked with).
     */
    disposeScanning() {
      try {
        Atalasoft.Controls.Capture.WebScanning.dispose(
          this.disposeSuccessCallback,
          this.disposeErrorCallback
        );
      } catch (e) {
        console.error(e.toString());
      }
    },

    /**
     * reset variables
     */
    resetScanVariables() {
      try {
        this.scanners = [];
        this.selectedEnhancementOptions = [];
        this.acquiredImages = [];
        this.selectedScanner = undefined;
        this.uploadError = undefined;
        this.isLoadingScanners = false;
        this.isUploadingPages = false;
        this.isScanning = false;
      } catch (e) {
        console.error(`Couldn't reset ${e}`);
      }
    }
  }
};
