import { imageOperation } from "@/model/image/imageModel";
import {
  stampNames,
  annotationNames
} from "@/model/documentViewer/annotationControllerModel";
import { recordOperation, recordOperations } from "@/model/record/recordModel";

export const viewerCommandsMixin = {
  methods: {
    /**
     * Is allowed Toolbar Operation
     * @param operation
     * @returns {boolean}
     */
    isAllowedToolbarOperation(operation) {
      switch (operation) {
        case imageOperation.Move:
          return (
            this.isAllowedAuthor() &&
            ((this.isValidOperation(this.recordOperation.CheckIn) &&
              this.isLatestVersion()) ||
              this.isWIP)
          );
        case imageOperation.Remove:
          return (
            this.isAllowedAuthor() &&
            ((this.isValidOperation(this.recordOperation.CheckIn) &&
              this.isLatestVersion()) ||
              this.isWIP)
          );
        case imageOperation.SavePage:
          return (
            this.isAllowedAuthor() &&
            ((this.isValidOperation(this.recordOperation.CheckIn) &&
              this.isLatestVersion()) ||
              this.isWIP)
          );
        case imageOperation.CheckIn:
          return this.visibleCommandCheckIn;
        case imageOperation.CheckOut:
          return this.visibleCommandCheckOut;
        case imageOperation.Release:
          return this.visibleCommandRelease;
        default:
          return true;
      }
    },

    /**
     * Is valid Toolbar Operation
     * @param operation
     * @returns {boolean}
     */
    isValidToolbarOperation(operation) {
      switch (operation) {
        case imageOperation.ZoomIn:
          return this.hasPages();
        case imageOperation.ZoomOut:
          return this.hasPages();
        case imageOperation.FitToPage:
          return this.hasPages();
        case imageOperation.BestFit:
          return this.hasPages();
        case imageOperation.FitToWidth:
          return this.hasPages();
        case imageOperation.RotateLeft:
          return this.enabledCommandRotate();
        case imageOperation.RotateRight:
          return this.enabledCommandRotate();
        case imageOperation.JumpFirstPage:
          return this.enabledCommandFirstPage();
        case imageOperation.NextPage:
          return this.enabledCommandNextPage();
        case imageOperation.JumpLastPage:
          return this.enabledCommandLastPage();
        case imageOperation.PreviousPage:
          return this.enabledCommandPreviousPage();
        case imageOperation.SelectPage:
          return this.hasPages();
        case imageOperation.Move:
          return this.enabledCommandMovePage();
        case imageOperation.Remove:
          return this.enabledCommandRemovePage();
        case imageOperation.SavePage:
          return this.enabledCommandSave();
        case imageOperation.CheckIn:
          return this.enabledCommandCheckIn();
        case imageOperation.CheckOut:
          return this.enabledCommandCheckOut();
        case imageOperation.Release:
          return this.enabledCommandRelease;
      }
    },

    /***
     * On Toolbar Action
     * @param operation
     */ async onDocumentAction(operation) {
      switch (operation) {
        case imageOperation.ZoomIn:
          this.zoomIn();
          break;
        case imageOperation.ZoomOut:
          this.zoomOut();
          break;
        case imageOperation.FitToPage:
          this.fitToPage();
          break;
        case imageOperation.BestFit:
          this.fitBest();
          break;
        case imageOperation.FitToWidth:
          this.fitToWidth();
          break;
        case imageOperation.RotateLeft:
          this.rotatePage(false);
          break;
        case imageOperation.RotateRight:
          this.rotatePage(true);
          break;
        case imageOperation.JumpFirstPage:
          this.goToFirstPage();
          break;
        case imageOperation.NextPage:
          this.goToNextPage();
          break;
        case imageOperation.JumpLastPage:
          this.goToLastPage();
          break;
        case imageOperation.PreviousPage:
          this.goToPreviousPage();
          break;
        case imageOperation.SelectPage:
          this.getTotalPages();
          break;
        case imageOperation.Move:
          this.visibleDialogMovePage = true;
          break;
        case imageOperation.Remove:
          this.visibleDialogRemovePage = true;
          break;
        case imageOperation.SavePage:
          this.saveEditedDocument(false);
          break;
        case imageOperation.CheckIn:
          this.performCheckInOperation();
          break;
        case imageOperation.CheckOut:
          await this.fileCheckOut();
          break;
        case imageOperation.Release:
          this.visibleDialogFileRelease();
          break;
        case recordOperation.Download: {
          this.showDownloadFileDialog();
          break;
        }
        case recordOperation.Print: {
          this.showPrintFileDialog();
          break;
        }
        case recordOperation.Email: {
          this.showEmailFileDialog();
          break;
        }
      }
    },

    /***
     * Add Divider between Command if Required
     * @param operation
     */
    requiresDivider(operation) {
      switch (operation) {
        case imageOperation.FitToPage:
          return true;
        case imageOperation.RotateLeft:
          return true;
        case imageOperation.JumpFirstPage:
          return true;
        case imageOperation.Move:
          return this.isLatestVersion() || this.isWIP;
        case imageOperation.CheckIn:
          return this.visibleCheckInDivider;
      }
    },

    /***
     * Annotation Action Command
     * @param operation
     */
    onAnnotationAction(operation) {
      switch (operation) {
        case annotationNames.Rectangle:
          this.drawRectangle();
          break;
        case annotationNames.Ellipse:
          this.drawEllipse();
          break;
        case annotationNames.Image:
          this.drawImage();
          break;
        case annotationNames.Highlight:
          this.drawHighlight();
          break;
        case annotationNames.StickyNote:
          this.drawText();
          break;
        case annotationNames.Line:
          this.drawSingleLine();
          break;
        case annotationNames.Lines:
          this.drawMultipleLines();
          break;
        case annotationNames.Freehand:
          this.drawFreeHand();
          break;
        case annotationNames.Polygon:
          this.drawPolygon();
          break;
      }
    },

    /***
     * Annotation Stamp Action Command
     * @param operation
     */
    onAnnotationStampAction(operation) {
      switch (operation) {
        case stampNames.Confidential:
          this.drawConfidentialStamp();
          break;
        case stampNames.Classified:
          this.drawClassifiedStamp();
          break;
        case stampNames.Important:
          this.drawImportantStamp();
          break;
        case stampNames.Draft:
          this.drawDraftStamp();
          break;
        case stampNames.TopSecret:
          this.drawTopSecretStamp();
          break;
        case stampNames.DateTime:
          this.drawDateAndTimeStamp();
          break;
      }
    },

    /**
     * record Send Operations
     * @return {{name: string, icon: string, label: string, tooltip: string}[]}
     */
    recordSendOperations() {
      return recordOperations.filter(
        el =>
          el.name === recordOperation.Download ||
          el.name === recordOperation.Print ||
          el.name === recordOperation.Email
      );
    },

    /**
     * Set of different enables commands for document viewer Actions
     * @return {boolean}
     */
    enabledCommandCheckOut() {
      return (
        this.isAllowedAuthor() &&
        this.isValidOperation(recordOperation.CheckOut) &&
        this.isLatestVersion() &&
        this.hasPages()
      );
    },
    enabledCommandRotate() {
      return (
        (this.canRotatePage() || this.visibleAutoSaveActions) && this.hasPages()
      );
    },
    enabledCommandFirstPage() {
      return this.canGoToFirstPage() || this.hasPages();
    },
    enabledCommandPreviousPage() {
      return this.canGoToPreviousPage() || this.hasPages();
    },
    enabledCommandNextPage() {
      return this.canGoToNextPage() || this.hasPages();
    },
    enabledCommandLastPage() {
      return this.canGoToLastPage() || this.hasPages();
    },
    enabledCommandRemovePage() {
      return this.visibleAutoSaveActions && this.hasPages();
    },
    enabledCommandMovePage() {
      return this.visibleAutoSaveActions && this.hasPages();
    },
    enabledCommandSave() {
      return this.visibleAutoSaveActions && this.hasPages();
    },
    enabledCommandCheckIn() {
      return (
        (this.isValidOperation(recordOperation.CheckIn) || this.isWIP) &&
        this.hasPages()
      );
    },
    enableCommandDownload() {
      return (
        (this.isAllowedOperation(recordOperation.Download) ||
          this.isAllowedOperation(recordOperation.Print) ||
          this.isAllowedOperation(recordOperation.Email)) &&
        this.hasPages() &&
        !this.isCompoundDocument
      );
    },

    enableCommandSend(operation) {
      return !this.isValidOperation(operation) && !this.isWIP;
    }
  }
};
