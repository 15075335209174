// design
import { Color } from "@/design/colors/Color";

// vuex/store
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("solution");

/**
 * Menu Item Settings Mixin encapsulate Menu Item Settings logic
 */
export const menuItemSettingsMixin = {
  computed: {
    ...mapGetters({
      menuItemColor: "menuItemColor"
    }),
    /**
     * Get Material Design Menu Item Color
     * @returns {Color}
     */
    mdMenuItemColor() {
      return new Color(
        this.menuItemColor.name,
        this.menuItemColor.variantType,
        this.menuItemColor.variantNumber
      );
    },
    /**
     * Get menu Item color class
     * @returns {string|*}
     */
    menuItemColorClass() {
      return this.mdMenuItemColor.getClassColor();
    }
  }
};
