// design
import {
  iconDownload,
  iconPdf,
  iconDropDownload,
  iconPrint,
  iconSend,
  iconClose
} from "@/design/icon/iconConst";

// model
import { fieldDownloadType } from "@/model/document/documentModel";
import {
  createAnnotateOption,
  createPrintOption
} from "@/model/image/imageModel";

// vuex
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("document");

export const annotationOptionMixin = {
  components: {
    BaseDropDownMenuButton: () =>
      import("@/components/shared/base/BaseDropDownMenuButton"),
    BaseTooltipButton: () =>
      import("@/components/shared/base/BaseTooltipButton"),
    DownloadAnnotationOption: () =>
      import(
        "@/components/shared/core/image/Annotation/DownloadAnnotationOption"
      ),
    PrintAnnotationOption: () =>
      import("@/components/shared/core/image/Annotation/PrintAnnotationOption"),
    LayoutPopoverMenu: () =>
      import("@/components/shared/core/layout/menu/LayoutPopoverMenu")
  },
  data() {
    return {
      /**
       * @type {{includeAnnotations: boolean, burnIntoImage: boolean, maintainColor: boolean, includeRedaction: boolean, burnRedaction: boolean}}
       */
      annotateOption: createAnnotateOption(),

      /**
       * print annotate-able Print image Option
       * @type {{burnAnnotations: boolean, burnRedaction: boolean}}
       */
      printOption: createPrintOption(),

      // icons TODO: move it to a corresponded operation model
      iconDownload: iconDownload,
      iconPdf: iconPdf,
      iconDropDownload: iconDropDownload,
      iconPrint: iconPrint,
      iconSend: iconSend,
      iconClose: iconClose,

      // Document Const Model
      asItIs: fieldDownloadType.asItIs,
      asPdf: fieldDownloadType.asPdf
    };
  },
  methods: {
    ...mapActions({
      downloadFile: "downloadFile",
      downloadFileAsPdf: "downloadFileAsPdf",
      downloadImageFileVersion: "downloadImageFileVersion",
      downloadFileVersionAsPdf: "downloadFileVersionAsPdf"
    })
  }
};
