/**
 * action Result Type template
 * @type {Readonly<{type: string, message: string}>}
 */
const actionResult = Object.freeze({
  type: "type",
  message: "message",
  outcome: "outcome"
});

/**
 * action Result Type name/key
 * @type {Readonly<{cancel: string, abort: string, success: string, error: string}>}
 */
const actionResultType = Object.freeze({
  success: "success",
  error: "error",
  cancel: "cancel",
  abort: "abort"
});

/**
 * action Result Types
 * @type {{name: string, outcome: string}[]}
 */
const actionResultTypes = Object.freeze([
  {
    name: actionResultType.success,
    outcome: "succeeded"
  },
  {
    name: actionResultType.error,
    outcome: "failed"
  },
  {
    name: actionResultType.cancel,
    outcome: "canceled"
  },
  {
    name: actionResultType.abort,
    outcome: "aborted"
  }
]);

/**
 * find Action Result Type
 * @param {string} name Action Result Type name
 * @return {{name: string, outcome: string, message: string}} Action Result Type
 */
const findActionResultType = name => {
  return actionResultTypes.find(el => el.name === name);
};

/**
 * create Action Result
 * @param {string} type Action Result type
 * @param {string} action Action name
 * @return {{type: string, message: string, outcome: string}} Action Result
 */
const createActionResult = (type, action) => {
  const outcome = findActionResultType(type)?.outcome ?? "";

  return {
    [actionResult.type]: type,
    [actionResult.message]: formatActionMessage(action, outcome),
    [actionResult.outcome]: findActionResultType(type)?.outcome
  };
};

/**
 * create Success Action Result
 * @param {string} action Action name
 * @return {{type: string, message: string, outcome: string}} Success Action Result
 */
const createActionResultSuccess = action => {
  return createActionResult(actionResultType.success, action);
};

/**
 * create Cancel Action Result
 * @param {string} action Action name
 * @return {{type: string, message: string, outcome: string}} Cancel Action Result
 */
const createActionResultCancel = action => {
  return createActionResult(actionResultType.cancel, action);
};

/**
 * create Error Action Result
 * @param {string} action Action name
 * @return {{type: string, message: string, outcome: string}} Error Action Result
 */
const createActionResultError = action => {
  return createActionResult(actionResultType.error, action);
};

/**
 * create Abort Action Result
 * @param {string} action Action name
 * @return {{type: string, message: string, outcome: string}} Abort Action Result
 */
const createActionResultAbort = action => {
  return createActionResult(actionResultType.abort, action);
};

/**
 * format Action Message
 * @param action Action name
 * @param outcome Action's outcome
 * @return {`${string} ${string}.`} formatted Action Message
 */
const formatActionMessage = (action, outcome) => {
  return `${action} ${outcome}.`;
};

export {
  actionResult,
  actionResultType,
  actionResultTypes,
  createActionResult,
  createActionResultSuccess,
  createActionResultCancel,
  createActionResultError,
  createActionResultAbort,
  findActionResultType,
  formatActionMessage
};
