import {
  iconTrash,
  iconMovePage,
  iconZoomIn,
  iconZoomOut,
  iconFitToPage,
  iconFitToWidth,
  iconRotateLeft,
  iconRotateRight,
  iconResize,
  iconFullBack,
  iconSingleBackward,
  iconFullForward,
  iconSingleForward,
  iconSave,
  iconFileCheckIn,
  iconFileCheckOut,
  iconFileRelease,
  iconScanner,
  iconImport,
  iconExport
} from "@/design/icon/iconConst";

/**
 * annotate Option  (Current implementation)
 * @type {Readonly<{annotationsBurn: string, annotationsBurnMaintainColor: string, redactionsBurn: string, annotationsInclude: string, redactionsInclude: string}>}
 */
const annotateOption = Object.freeze({
  annotationsInclude: "includeAnnotations",
  annotationsBurn: "burnIntoImage",
  annotationsBurnMaintainColor: "maintainColor",
  redactionsInclude: "includeRedaction",
  redactionsBurn: "burnRedaction"
});

/**
 * Image Operation
 * @type {Readonly<{Move: string, Remove: string}>}
 */
const imageOperation = Object.freeze({
  ZoomIn: "Zoom In",
  ZoomOut: "Zoom Out",
  FitToPage: "Fit To Page",
  BestFit: "Best Fit",
  FitToWidth: "Fit To Width",
  RotateLeft: "Rotate Page Left",
  RotateRight: "Rotate Page Right",
  JumpFirstPage: "Jump to First Page",
  JumpLastPage: "Jump to Last Page",
  PreviousPage: "Previous Page",
  NextPage: "Next Page",
  SelectPage: "Select Page",
  Move: "Move",
  Remove: "Remove",
  SavePage: "Save Document",
  CheckIn: "Check In",
  CheckOut: "Check Out",
  Scan: "Scan",
  Import: "Import",
  Release: "Release",
  ImportAnnotation: "Import Annotation",
  ExportAnnotation: "Export Annotation"
});

/**
 * annotate Options
 * @type {{note: string, hint: string, name: string, label: string}[]}
 */
const annotateOptions = Object.freeze([
  {
    name: annotateOption.annotationsInclude,
    label: "Include Annotations",
    hint:
      "Include Annotations when exporting an image file containing annotations",
    note:
      "Annotations will be included when exporting an image file containing annotations"
  },
  {
    name: annotateOption.annotationsBurn,
    label: "Burn Annotations into Image",
    hint: "Burn Annotations into current Image",
    note:
      "Annotations will burned into an image when exporting an image file containing annotations"
  },
  {
    name: annotateOption.annotationsBurnMaintainColor,
    label: "Maintain Annotation Color",
    hint: "Maintain Annotation Color when Burn Annotations into current Image",
    note: "Drastically increases image file size"
  },
  {
    name: annotateOption.redactionsInclude,
    label: "Include Redactions",
    hint:
      "Include Redactions when exporting an image file containing annotations",
    note:
      "Redactions will be included when exporting an image file containing annotations"
  },
  {
    name: annotateOption.redactionsBurn,
    label: "Burn Redactions",
    hint: "Burn Redactions into current Image",
    note:
      "Redactions will burned into an image when exporting an image file containing annotations"
  }
]);

/**
 * find Annotate Option
 * @param {string} name Annotate Option
 * @return {{note: string, hint: string, name: string, label: string}}
 */
const findAnnotateOption = name => {
  return annotateOptions.find(el => el.name === name);
};

/**
 * create Annotate Option (Current implementation)
 * @param {boolean} annotationsInclude include annotations
 * @param {boolean} annotationsBurn burn include annotations
 * @param {boolean} annotationsBurnMaintainColor Maintain Color when burn annotations
 * @param {boolean} redactionsInclude include redactions
 * @param {boolean} redactionsBurn burn included redactions
 * @return {{includeAnnotations: boolean, burnIntoImage: boolean, maintainColor: boolean, includeRedaction: boolean, burnRedaction: boolean}}
 */
const createAnnotateOption = (
  annotationsInclude = true,
  annotationsBurn = true,
  annotationsBurnMaintainColor = true,
  redactionsInclude = true,
  redactionsBurn = true
) => {
  return {
    [annotateOption.annotationsInclude]: annotationsInclude,
    [annotateOption.annotationsBurn]: annotationsBurn,
    [annotateOption.annotationsBurnMaintainColor]: annotationsBurnMaintainColor,
    [annotateOption.redactionsInclude]: redactionsInclude,
    [annotateOption.redactionsBurn]: redactionsBurn
  };
};

/**
 * export Annotate Options (new proposed implementation)
 * @type {Readonly<{redactions: string, annotations: string}>}
 */
const exportAnnotateOptions = Object.freeze({
  annotations: "annotations",
  redactions: "redactions"
});

/**
 * export Annotations Option
 * @type {Readonly<{include: string, burn: string, burnOption: string}>}
 */
const exportAnnotationsOption = Object.freeze({
  include: "include",
  burn: "burn",
  burnOption: "burnOption"
});

/**
 * annotations Burn Option
 * @type {Readonly<{maintainColor: string}>}
 */
const annotationsBurnOption = Object.freeze({
  maintainColor: "maintainColor"
});

/**
 * export Redactions Option
 * @type {Readonly<{include: string, burn: string}>}
 */
const exportRedactionsOption = Object.freeze({
  include: "include",
  burn: "burn"
});

/**
 * create Export Annotate Options (new proposed implementation)
 * @param {boolean} annotationsInclude
 * @param {boolean} annotationsBurn
 * @param {boolean} annotationsBurnMaintainColor
 * @param {boolean} redactionsInclude
 * @param {boolean} redactionsBurn
 * @return {{annotations: {include: boolean, burn: boolean, burnOption: {maintainColor:boolean}}, redactions: {include: boolean, burn: boolean}}}
 */
const createExportAnnotateOptions = (
  annotationsInclude = true,
  annotationsBurn = true,
  annotationsBurnMaintainColor = true,
  redactionsInclude = true,
  redactionsBurn = true
) => {
  return {
    [exportAnnotateOptions.annotations]: {
      [exportAnnotationsOption.include]: annotationsInclude,
      [exportAnnotationsOption.burn]: annotationsBurn,
      [exportAnnotationsOption.burnOption]: {
        [annotationsBurnOption.maintainColor]: annotationsBurnMaintainColor
      }
    },
    [exportAnnotateOptions.redactions]: {
      [exportRedactionsOption.include]: redactionsInclude,
      [exportRedactionsOption.burn]: redactionsBurn
    }
  };
};

/**
 * print annotate-able image Option enum names
 * @type {Readonly<{burnAnnotations: string, burnRedaction: string, getEditFilePath: String}>}
 */
const printOption = Object.freeze({
  burnAnnotations: "burnAnnotations",
  burnRedaction: "burnRedaction",
  getEditFilePath: "getEditFilePath" // TODO: getEditFilePath is not a self-explanatory name, we should consider renaming it
});

/**
 * find Print annotate-able Option
 * @param {string} name Print Option
 * @return {{note: string, hint: string, name: string, label: string}}
 */
const findPrintOption = name => {
  return printOptions.find(el => el.name === name);
};

/**
 * print annotate-able image Options
 * @type {{note: string, hint: string, name: string, label: string}[]}
 */
const printOptions = Object.freeze([
  {
    name: printOption.burnAnnotations,
    label: "Include Annotations",
    hint: "Include (burned) all Annotations when specified image being printed",
    note:
      "All Annotations will be included (burned) when specified image being printed"
  },
  {
    name: printOption.burnRedaction,
    label: "Include Redaction",
    hint: "Include (burned) all Redactions when specified image being printed",
    note:
      "All Annotations will be included (burned) when specified image being printed"
  },
  {
    name: printOption.getEditFilePath,
    label: "get Edit File Path",
    hint: "Sameed TODO:  get Edit File Path",
    note: "Sameed TODO:  get Edit File Path"
  }
]);

/** Image Operations
 * NOTE : Expand interface on Demand
 * @type {{name: string, icon: string, label: string}[]}
 */
const imageOperations = Object.freeze([
  {
    name: imageOperation.Move,
    label: imageOperation.Move,
    icon: iconMovePage
  },
  {
    name: imageOperation.Remove,
    label: imageOperation.Remove,
    icon: iconTrash
  },
  {
    name: imageOperation.Scan,
    label: imageOperation.Scan,
    icon: iconScanner
  },
  {
    name: imageOperation.Import,
    label: "Import File",
    icon: iconImport
  },
  {
    name: imageOperation.ImportAnnotation,
    label: "Import Annotations",
    icon: iconImport
  },
  {
    name: imageOperation.ExportAnnotation,
    label: "Export Annotations",
    icon: iconExport
  }
]);

/**
 * create annotate-able Print image Option
 * @param {boolean} burnAnnotations burn Annotations
 * @param {boolean} burnRedaction burn Redaction
 * @param {boolean} getEditFilePath
 * @return {{burnAnnotations: boolean, burnRedaction: boolean, getEditFilePath: boolean}}
 */
const createPrintOption = (
  burnAnnotations = true,
  burnRedaction = true,
  getEditFilePath = false
) => {
  return {
    [printOption.burnAnnotations]: burnAnnotations,
    [printOption.burnRedaction]: burnRedaction,
    [printOption.getEditFilePath]: getEditFilePath
  };
};

/** find Image Operation
 * @param name image operation name
 * @return {{name: string, icon: string, label: string}}
 */
const findImageOperation = name => {
  return imageOperations?.find(el => el?.name === name);
};

/** Image Operations
 * NOTE : Expand interface on Demand
 * @type {{name: string,label: string, toolTip:string, icon: string}[]}
 */
const imageViewerToolbarActions = Object.freeze([
  {
    name: imageOperation.ZoomIn,
    label: imageOperation.ZoomIn,
    toolTip: imageOperation.ZoomIn,
    icon: iconZoomIn
  },
  {
    name: imageOperation.ZoomOut,
    label: imageOperation.ZoomOut,
    toolTip: imageOperation.ZoomOut,
    icon: iconZoomOut
  },
  {
    name: imageOperation.FitToPage,
    label: imageOperation.FitToPage,
    toolTip: imageOperation.FitToPage,
    icon: iconFitToPage
  },
  {
    name: imageOperation.FitToWidth,
    label: imageOperation.FitToWidth,
    toolTip: imageOperation.FitToWidth,
    icon: iconFitToWidth
  },
  {
    name: imageOperation.BestFit,
    label: imageOperation.BestFit,
    toolTip: imageOperation.BestFit,
    icon: iconResize
  },
  {
    name: imageOperation.RotateLeft,
    label: imageOperation.RotateLeft,
    toolTip: imageOperation.RotateLeft,
    icon: iconRotateLeft
  },
  {
    name: imageOperation.RotateRight,
    label: imageOperation.RotateRight,
    toolTip: imageOperation.RotateRight,
    icon: iconRotateRight
  },
  {
    name: imageOperation.JumpFirstPage,
    label: imageOperation.JumpFirstPage,
    toolTip: imageOperation.JumpFirstPage,
    icon: iconFullBack
  },
  {
    name: imageOperation.PreviousPage,
    label: imageOperation.PreviousPage,
    toolTip: imageOperation.PreviousPage,
    icon: iconSingleBackward
  },
  {
    name: imageOperation.SelectPage,
    label: imageOperation.SelectPage,
    toolTip: imageOperation.SelectPage
  },
  {
    name: imageOperation.NextPage,
    label: imageOperation.NextPage,
    toolTip: imageOperation.NextPage,
    icon: iconSingleForward
  },
  {
    name: imageOperation.JumpLastPage,
    label: imageOperation.JumpLastPage,
    toolTip: imageOperation.JumpLastPage,
    icon: iconFullForward
  },
  {
    name: imageOperation.Move,
    label: imageOperation.Move,
    toolTip: imageOperation.Move,
    icon: iconMovePage
  },
  {
    name: imageOperation.Remove,
    label: imageOperation.Remove,
    toolTip: imageOperation.Remove,
    icon: iconTrash
  },
  {
    name: imageOperation.SavePage,
    label: imageOperation.SavePage,
    toolTip: imageOperation.SavePage,
    icon: iconSave
  },
  {
    name: imageOperation.CheckIn,
    label: imageOperation.CheckIn,
    toolTip: imageOperation.CheckIn,
    icon: iconFileCheckIn
  },
  {
    name: imageOperation.CheckOut,
    label: imageOperation.CheckOut,
    toolTip: imageOperation.CheckOut,
    icon: iconFileCheckOut
  },
  {
    name: imageOperation.Release,
    label: imageOperation.Release,
    toolTip: imageOperation.Release,
    icon: iconFileRelease
  }
]);

/** find Image Viewer Toolbar Operation
 * @param name image operation name
 * @return {{name: string,label: string, toolTip:string, icon: string}}
 */
const findImageViewerToolbarOperation = name => {
  return imageViewerToolbarActions?.find(el => el?.name === name);
};

/**
 * Import Annotation Options
 * @type {Readonly<{customPage: number, allPages: number}>}
 */
const importAnnotationOptions = Object.freeze({
  customPage: 1,
  allPages: 2
});

/**
 * Export Annotation Options
 * @type {Readonly<{customPage: number, allPages: number}>}
 */
const exportAnnotationOptions = Object.freeze({
  customPage: 1,
  allPages: 2
});
export {
  annotateOption,
  printOption,
  printOptions,
  imageOperation,
  createAnnotateOption,
  createPrintOption,
  createExportAnnotateOptions,
  findPrintOption,
  findAnnotateOption,
  findImageOperation,
  findImageViewerToolbarOperation,
  imageViewerToolbarActions,
  importAnnotationOptions,
  exportAnnotationOptions
};
