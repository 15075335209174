// design
import { iconScanner } from "@/design/icon/iconConst";

// model
import {
  findImageEnhancement,
  imageEnhancement,
  imageEnhancementNames,
  imageEnhancements
} from "@/model/acquire/scan/scanningOptions";
import { isArray, isEmpty, toLowerCaseSafe } from "@/utils";

// mixins
import { baseComponentMixin } from "@/mixins/shared/base/component/baseComponentMixin";
import { userMixin } from "@/mixins/shared/user/userMixin";

export const basicScanMixin = {
  mixins: [baseComponentMixin, userMixin],
  data() {
    return {
      iconScanner: iconScanner,

      // scanning variables
      scanners: [],
      acquiredImages: [],
      selectedScanner: undefined,
      uploadError: undefined,
      isLoadingScanners: false,
      isUploadingPages: false,
      isScanning: false,

      // image enhancements
      enhancementNames: imageEnhancement,
      imageEnhancements: imageEnhancements,
      imageEnhancementNames: imageEnhancementNames,
      selectedEnhancementOptions: []
    };
  },
  computed: {
    /**
     * Abstract get current Record
     * @return {{id:number, name:string} | undefined}
     */
    currentRecord() {
      console.warn("Called Abstract computed currentRecord");
      return undefined;
    },
    /**
     * Abstract get web Capture Service Name
     * @return {string|undefined} return web Capture Service Name
     */
    webCaptureServiceName() {
      console.warn("Called Abstract computed webCaptureServiceName");
      return undefined;
    },

    /**
     * get default Twain Scanner
     * @return {undefined} return default Scanner
     */
    defaultScanner() {
      console.warn("Called Abstract computed defaultScanner");
      return undefined;
    },

    /**
     * get scan Command Name
     * @return {string} returns scan Command Name
     */
    scanCommandName() {
      return "Scan";
    },

    /**
     * scan Command Tooltip
     * @return {string} returns  scan Command Tooltip
     */
    scanCommandTooltip() {
      return this.selectedScanner
        ? `Start scanning pages using ${this.selectedScanner}`
        : `Start scanning pages`;
    },

    /**
     * label of Selected Scanner
     * @return {string} returns label of Selected Scanner
     */
    labelSelectedScanner() {
      return this.selectedScanner ? `Scanner` : `Select Scanner`;
    },

    /**
     * indicate whether Scan Command is enabled
     * @return {boolean} returns whether Scan Command is enabled
     */
    enabledScanCommand() {
      return !!this.selectedScanner;
    },
    /**
     * scanner Count
     * @return {number|number} returns scanner Count
     */
    scannerCount() {
      return this.scanners?.length ?? 0;
    },

    /**
     * get all available scanner names
     * @return {{string}[]} returns all available scanner names
     */
    scannerNames() {
      return this.scanners ?? [];
    },

    anyScanningOption() {
      return (this.selectedEnhancementOptions?.length ?? 0) > 0;
    },

    acquiredImageCount() {
      return this.acquiredImages?.length ?? 0;
    },

    anyAcquiredImage() {
      return this.acquiredImageCount > 1;
    },

    acquiredImageInfo() {
      return this.isScanning
        ? this.acquiredImageCount > 1
          ? `Acquired ${this.acquiredImageCount} images.`
          : `Acquired ${this.acquiredImageCount} image.`
        : this.acquiredImageCount > 0
        ? this.acquiredImageCount > 1
          ? `Ready to upload ${this.acquiredImageCount} acquired images.`
          : `Ready to upload ${this.acquiredImageCount} acquired image.`
        : "";
    },

    /**
     * Exists Upload Error
     * @return {boolean}
     */
    hasUploadError() {
      return !isEmpty(this.uploadError?.toString());
    },

    /**
     * user-friendly upload Progress Info
     * @return {string}
     */
    uploadProgressInfo() {
      if (this.isUploadingPages) {
        return `Uploading ${this.acquiredImageCount} scanned image file ...`;
      }
      if (this.checkingInScannedImage) {
        return `Checking In ${this.acquiredImageCount} uploaded scanned image file ...`;
      }
      return "";
    },

    /**
     * get selected Enhancement Option Labels
     * @return {string[]}
     */
    selectedEnhancementOptionLabels() {
      return this.selectedEnhancementOptions.map(
        name => findImageEnhancement(name)?.label
      );
    }
  },
  methods: {
    /**
     * Abstract Checks if Web Scanning control is initialized or not.
     * @return {boolean}
     */
    isInitialized() {
      console.warn("Called Abstract method: isInitialized()");
      return false;
    },

    /**
     * Abstract default Scanning Options
     * @return {undefined}
     */
    defaultScanningOptions() {
      console.warn("Called Abstract method: defaultScanningOptions()");
      return undefined;
    },

    /**
     * Abstract display Scanners and select default scanner if it is available
     */
    displayScanners() {
      console.warn("Called Abstract method: displayScanners()");
      return undefined;
    },

    /**
     * Abstract select Scanner
     * @param {string} scanner scanner name
     */
    selectScanner(scanner) {
      console.warn(
        "Called Abstract method: selectedScanner() scanner:",
        scanner
      );
    },

    /**
     * Abstract Scan pages using Selected Scanner
     */
    scan() {
      console.warn("Called Abstract method: scan()");
    },

    /**
     * Abstract abort current Scanning process
     * @return {undefined}
     */
    abortScan() {
      console.warn("Called Abstract method: abortScan()");
    },

    /**
     * Abstract Get scanning Options
     * @return {any|undefined}
     */
    scanningOptions() {
      console.warn("Called Abstract method: scanningOptions()");
      return undefined;
    },

    /**
     * Abstract dispose Scanning
     * This method should be called to shutdown all Web Capture services
     * gracefully and free all acquired resources
     * (for example, correctly close scanners that web application worked with).
     */
    disposeScanning() {
      console.warn("Called Abstract method: disposeScanning()");
    },

    /**
     * Abstract afterUploadCompleted
     * remarks: this method will be called by uploadCompletedHandler
     * @param success
     */
    afterUploadCompleted(success) {
      console.warn(
        "Called Abstract method: afterUploadCompleted() success:",
        success
      );
    },

    /**
     * find Scanner
     * @param {string} scanner scanner name
     * @return {string} returns found scanner name
     */
    findScanner(scanner) {
      return this.scanners?.find(
        sc => toLowerCaseSafe(sc) === toLowerCaseSafe(scanner ?? "")
      );
    },

    /**
     * web Capture msi installer Name
     * @param {string} installerName WebCapture msi installer Name
     * @return {string} returns web Capture msi installer Url
     */
    webCaptureInstallerUrl(installerName) {
      return `${window.location.protocol}//${window.location.host}/WebCapture/${installerName}`;
    },

    /**
     * clear Current Error
     */
    clearCurrentError() {
      this.currentError = undefined;
    },

    /**
     * set Current Error (will concatenate all current errors)
     * @param {string} error
     */
    setCurrentError(error) {
      console.error(error);
      this.currentError = this.hasError
        ? `${this.currentError}. ${error}`
        : error;
    },

    /**
     * set Upload Error (will concatenate all Upload errors)
     * @param {string} error
     */
    setUploadError(error) {
      console.error(error);
      this.uploadError = this.uploadError
        ? `${this.uploadError}. ${error}`
        : error;
    },

    /**
     * clear Upload Error
     */
    clearUploadError() {
      this.uploadError = undefined;
    }
  },

  watch: {
    /**
     * watch scanner selection changes
     * @param {string} scanner scanner name
     */
    selectedScanner(scanner) {
      try {
        this.selectScanner(scanner);
      } catch (e) {
        this.setCurrentError(e);
      }
    },
    /**
     * If we want to use: v-checkbox v-model="selectedEnhancementOptions"
     * @param {{string}[]} newSelection
     */
    selectedEnhancementOptions(newSelection) {
      try {
        this.clearCurrentError();

        const options = this.scanningOptions();

        console.log(`selectedEnhancementOptions() scanningOptions:`, options);

        if (isArray(newSelection)) {
          this.imageEnhancementNames.forEach(eon => {
            const isSelected = !!newSelection.find(el => el === eon);
            if (isSelected) {
              if (!options[eon]) {
                options[eon] = true;
                console.log(`changed option ${eon}`, options[eon]);
                if (eon === imageEnhancement.autoRotate) {
                  // if imageEnhancement.autoRotate, then must set:
                  //  1. - imageEnhancement.applyVRS
                  options[imageEnhancement.applyVRS] = true;
                  console.log(
                    `option ${imageEnhancement.applyVRS}`,
                    options[imageEnhancement.applyVRS]
                  );

                  // 2. - imageEnhancement.deskew
                  if (
                    !this.selectedEnhancementOptions.find(
                      el => el === imageEnhancement.deskew
                    )
                  ) {
                    this.selectedEnhancementOptions.push(
                      imageEnhancement.deskew
                    );
                  }

                  options[imageEnhancement.deskew] = true;
                  console.log(
                    `option ${imageEnhancement.deskew}`,
                    options[imageEnhancement.deskew]
                  );
                }
              }
            } else {
              // note: applyVRS not listed for user interaction (it will be switched automatically)
              if (eon !== imageEnhancement.applyVRS) {
                if (options[eon]) {
                  options[eon] = false;
                  console.log(`changed - option ${eon}`, options[eon]);
                }
              }
            }
          });
        } else {
          this.imageEnhancementNames.forEach(eon => {
            if (options[eon]) {
              options[eon] = false;
              console.log(`changed option ${eon}`, options[eon]);
            }
          });
        }
      } catch (e) {
        this.setCurrentError(e);
      }
    }
  }
};
