/**
 * imageEnhancement
 * note matches Atalasoft scanningOptions["applyVRS", "autoRotate", ...]
 * @type {Readonly<{deskew: string, despeckle: string, autoCrop: string, holeFill: string, discardBlankPages: string, autoRotate: string}>}
 */
const imageEnhancement = Object.freeze({
  applyVRS: "applyVRS",
  autoRotate: "autoRotate",
  deskew: "deskew",
  despeckle: "despeckle",
  autoCrop: "autoCrop",
  holeFill: "holeFill",
  discardBlankPages: "discardBlankPages"
});

/**
 * image Enhancements
 * @type {{name: string, description: string, label: string}[]}
 */
const imageEnhancements = Object.freeze([
  {
    name: imageEnhancement.applyVRS,
    label: "Apply VRS",
    description: "Enable or disable VRS post-processing in general"
  },
  {
    name: imageEnhancement.autoRotate,
    label: "Auto Rotate",
    description:
      "Detect the orientation of the text in an image - right-side up, upside-down, sideways - and rotates the image so the text is upright."
  },
  {
    name: imageEnhancement.deskew,
    label: "Deskew",
    description:
      "Deskew is scanning jargon for 'straighten' - to rotate the scanned image by a few degrees to correct for the paper being scanned slightly crooked."
  },
  {
    name: imageEnhancement.despeckle,
    label: "Despeckle",
    description:
      "Scanning option indicating whether 'despeckle' filter is applied to the acquired image"
  },
  {
    name: imageEnhancement.autoCrop,
    label: "Auto Crop",
    description:
      "Scanning option indicating whether 'auto crop' filter is applied to the acquired image"
  },
  {
    name: imageEnhancement.holeFill,
    label: "Hole Fill",
    description:
      "Scanning option indicating whether 'hole fill' filter is applied to the acquired image"
  },
  {
    name: imageEnhancement.discardBlankPages,
    label: "Discard Blank Pages",
    description:
      "When this option is true, blank images are detected and discarded during scanning. In duplex scanning, front and back sides of pages are discarded independently."
  }
]);

/**
 * find Image Enhancement
 * @param {string} name Image Enhancement name
 * @return {{name: string, description: string, label: string} | {name: string, description: string, label: string} | {name: string, description: string, label: string} | {name: string, description: string, label: string} | {name: string, description: string, label: string}}
 */
const findImageEnhancement = name =>
  imageEnhancements.find(ft => ft.name === name);

/**
 * image Enhancement Names
 * @type {string[]}
 */
const imageEnhancementNames = imageEnhancements?.map(el => el.name) ?? [];

export {
  imageEnhancement,
  imageEnhancements,
  findImageEnhancement,
  imageEnhancementNames
};
