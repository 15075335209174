const thumbEventNames = Object.freeze({
  /**
   * Triggers when thumbnail is deselected.
   */
  thumbDeselected: "thumbdeselected",
  /**
   * Triggers after thumbnail drag & drop operation is complete.
   */
  thumbDragComplete: "thumbdragcomplete",
  /**
   * Triggers before thumbnail drag & drop operation is complete.
   * This event could be triggered for either case when thumb was dropped within same document where it belongs or when thumb was dropped to the external document.
   */
  thumbDragend: "thumbdragend",
  /**
   * Triggers when thumbnail drag & drop operation is started.
   */
  thumbDragStart: "thumbdragstart",
  /**
   * Triggers when thumbnail is selected.
   */
  thumbSelected: "thumbselected"
});

export { thumbEventNames };
