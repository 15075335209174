const viewerEventNames = Object.freeze({
  /**
   * Triggers when viewer has been activated by another thumbnailer or reactivated by the same thumbnailer with changed document data.
   */
  activeDocumentChanged: "activedocumentchanged",
  /**
   * Triggers after a page has been removed.
   */
  pageRemoved: "pageremoved",
  /**
   * TTriggers after a page has been moved.
   */
  pageMoved: "pagemoved",
  /**
   * Triggers after a page has been rotated.
   */
  pageRotated: "pagerotated",
  /**
   * Triggers after a response from the server while saving the document.
   */
  documentSaved: "documentsaved",
  /**
   * Triggers when the control has encountered and error. Populated keys: e.name, e.message
   */
  error: "error"
});

/**
 * Viewer Editor Tabs
 * Expand on Demand
 * @type {Readonly<{annotation: {name: string, value: number}, text: {name: string, value: number}}>}
 */
const viewerEditorTabs = Object.freeze({
  text: { name: "Text", value: 0 },
  annotation: { name: "Annotation", value: 1 }
});

export { viewerEventNames, viewerEditorTabs };
