// vuex
import { createNamespacedHelpers } from "vuex";
import {
  SET_ANNOTATION_EDITOR,
  SET_TEXT_EDITOR
} from "@/store/user/mutation-types";
import {
  getEnableAnnotationEditor,
  getEnableTextEditor
} from "@/services/user/userService";
const { mapState, mapMutations } = createNamespacedHelpers("user");

export const viewerPropertiesMixin = {
  data() {
    return {
      showAnnotationTab: this.enableAnnotationEditor,
      showOcrTab: this.enableTextEditor
    };
  },
  computed: {
    ...mapState({
      enableTextEditor: "enableTextEditor",
      enableAnnotationEditor: "enableAnnotationEditor"
    })
  },
  methods: {
    ...mapMutations({
      setTextEditor: SET_TEXT_EDITOR,
      setAnnotationEditor: SET_ANNOTATION_EDITOR
    }),

    /**
     * Event onVisibleOcrTabChanged
     */
    onVisibleOcrTabChanged() {
      this.setTextEditor(this.showOcrTab);
    },

    /**
     * Event onVisibleAnnotationTabChanged
     */
    onVisibleAnnotationTabChanged() {
      this.setAnnotationEditor(this.showAnnotationTab);
    }
  },
  watch: {
    enableTextEditor() {
      this.showOcrTab = this.enableTextEditor;
    },
    enableAnnotationEditor() {
      this.showAnnotationTab = this.enableAnnotationEditor;
    }
  },

  mounted() {
    // Get Value from local storage and apply mutations
    this.setTextEditor(getEnableTextEditor()?.toLowerCase?.() === "true");
    this.showAnnotationTab = this.setAnnotationEditor(
      getEnableAnnotationEditor()?.toLowerCase?.() === "true"
    );

    // Set Default Editors value to pre-defined values
    this.showOcrTab = this.enableTextEditor;
    this.showAnnotationTab = this.enableAnnotationEditor;
  }
};
